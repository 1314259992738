import { IssuingEntity } from "./issuingEntity";

export class User {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  storeId: number;
  storeName: string;
  issuingEntites: IssuingEntity[];

  code: string;
  token: string;
  refreshToken: string;
  expiresTokenAt: Date;
  secondsRefreshToken: number;

  isInRole(role: string): boolean {
    return this.roles && this.roles.indexOf(role) !== -1;
  }
}
